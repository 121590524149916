import React from 'react'
import './about.css'
import ME2 from '../../assets/face2.PNG'
import { FaAward } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { VscFolderLibrary } from 'react-icons/vsc'

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME2} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>1 Year Working</small>

            </article>

            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>clients</h5>
              <small>300+ worldwide</small>

            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>80+</small>

            </article>
          </div>
          <p>
            Petah Tiqwa, Israel <br></br><br></br>

            Motivated Software Developer with a good experience managing all levels of large-scale projects,including budgeting and administration. 7 Years of experience in technical work,I have worked across multiple disciplines and multiple projects. I have managed teams, projects.
            <br></br>
            <br></br>
            I am a gamer that loves the process of bringing a vision to life. I thrive working in a team of like minded individuals and always welcome a technical challenge. Responsible for hiring and training junior Developers.
            <br></br>
            <br></br>
            Project Lead/Director- Responsible for all aspects of development for multiple products. Automation Developer- Responsible for automating complex Processes, and delivering simple solution for the client.
          </p>

          <a href="#contact" className="btn btn-primary about__content__btn">Let's Talk</a>
        </div>
      </div>

    </section>
  )
}

export default About