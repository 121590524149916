import React from 'react'
import './services.css'
import { BiCheck } from 'react-icons/bi'


const services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">

        <article className="service">
          <div className="service__head">
            <h3>Software Automation</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Will develop scripts for your daily tasks.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Will develop bots for your procces.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Will improve your daily work flow and provide usefull insights.</p>
            </li>
            {/* <li>
              <BiCheck className="service__list-icon" />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li> */}
          </ul>
        </article>
        {/* END OF UI/UX */}

        <article className="service">
          <div className="service__head">
            <h3>WEB Development</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Will design your application or website.</p>
            </li>
                      
            <li>
              <BiCheck className="service__list-icon" />
              <p>Will develop your application or website.</p>
            </li>
            
            <li>
              <BiCheck className="service__list-icon" />
              <p>Will deploy your application or website.</p>
            </li>
            
          </ul>
        </article>
        {/* END OF WEB Development */}

        <article className="service">
          <div className="service__head">
            <h3>Game Development</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Will design your game.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Will build game mechanics for your game.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>will develop code for your game.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>will help deploy your game.</p>
            </li>
            
          </ul>
        </article>
        {/* END OF Content Creation */}

      </div>

    </section>
  )
}

export default services