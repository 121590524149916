import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/cart.PNG'
import IMG2 from '../../assets/arrow.PNG'
import IMG3 from '../../assets/TD.PNG'
import GIF1 from '../../assets/cart.gif'
import GIF2 from '../../assets/arrow.gif'
import GIF3 from '../../assets/TD.gif'


const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Crazy Cart',
    github: "https://github.com/AntonP210/GameDev/tree/main/CrazyCart(Original)",
    demo: "https://antonpas.itch.io/crazycart",
  },
  {
    id: 2,
    image: IMG2,
    title: 'Impossible Arrow',
    github: "https://github.com/AntonP210/GameDev",
    demo: "https://antonpas.itch.io/impossiblearrow",
  },
  {
    id: 3,
    image: IMG3,
    title: 'Tower Defense',
    github: "https://github.com/AntonP210/GameDev/tree/main/TowerDefense",
    demo: "https://antonpas.itch.io/towerdefense",
  }
]
function GetTheGif(id) {
  if (id === 1) {
    return GIF1;
  }
  if (id === 2) {
    return GIF2;
  }
  if (id === 3) {
    return GIF3;
  }
}

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({ id, image, title, github, demo }) => {

            return (
              <article key={id} className="portfolio__item">

                <div className="portfolio__item-image">
                  <img src={image}

                    onMouseOver={e => (e.currentTarget.src = GetTheGif(id))}
                    onMouseOut={e => (e.currentTarget.src = image)}
                    alt={title}
                  />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a href={github} className="btn" target="_blank">Github</a>
                  <a href={demo} className="btn btn-primary" target="_blank">Live Demo</a>
                </div>

              </article>






            );
          })
        }
      </div>

    </section>
  )
}

export default Portfolio