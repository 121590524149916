import React from 'react';
import './contact.css';
import {MdOutlineEmail} from 'react-icons/md';
import {RiMessengerLine} from 'react-icons/ri';
import {BsWhatsapp} from 'react-icons/bs';
import emailjs from 'emailjs-com';
import { useRef } from 'react';

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.send('gmail', 'template_81xqq9m', form.current, '3T9O-Gkc5bAIBm1m9')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };


  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
        <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon"/>
           <h4>Email</h4> 
           <h5>antonpas.dev@gmail.com</h5>
           <a href="mailto:antonpas.dev@gmail.com">Send a message</a>
          </article>

        </div>
        {/* END OF CONTACT OPTIONS */}

         {/* <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Full Name" required/>
          <input type="email" name="email" placeholder="Your Email" required/>
          <textarea name="message" rows="7" placeholder="Your Message" required></textarea>
          <button type="submit" className="btn btn-primary">Send Message</button>
        </form>  */}
      </div>

    </section>
  )
}

export default Contact